import { createAction, props } from '@ngrx/store';
import { Map } from './map.model';

export enum MapActionType {
  GET_ALL_MAPS = '[Map] Get All Maps',
  GET_ALL_MAPS_SUCCESS = '[Map] Get All Maps :: Success',
  GET_MAP = '[Map] Get Map',
  GET_MAP_SUCCESS = '[Map] Get Map :: Success',
  CREATE_MAP = '[Map] Create Map',
  CREATE_MAP_SUCCESS = '[Map] Create Map :: Success',
  UPDATE_MAP = '[Map] Update Map',
  UPDATE_MAP_SUCCESS = '[Map] Update Map :: Success',
  RESET_MAP_UPDATED = '[Map] Reset Map Updated',
  DELETE_MAP = '[Map] Delete Map',
  DELETE_MAP_SUCCESS = '[Map] Delete Map :: Success',
  RESET_MAP_DELETED = '[Map] Reset Map Deleted',
}

export const getAllMaps = createAction(MapActionType.GET_ALL_MAPS);
export const getAllMapsSuccess = createAction(
  MapActionType.GET_ALL_MAPS_SUCCESS,
  props<{ maps: Map[] }>()
);
export const getMap = createAction(MapActionType.GET_MAP, props<{ id: string }>());
export const getMapSuccess = createAction(MapActionType.GET_MAP_SUCCESS, props<{ map: Map }>());
export const createMap = createAction(MapActionType.CREATE_MAP, props<{ map: Map }>());
export const createMapSuccess = createAction(
  MapActionType.CREATE_MAP_SUCCESS,
  props<{ map: Map }>()
);
export const updateMap = createAction(MapActionType.UPDATE_MAP, props<{ map: Map }>());
export const updateMapSuccess = createAction(
  MapActionType.UPDATE_MAP_SUCCESS,
  props<{ map: Map }>()
);
export const resetMapUpdated = createAction(MapActionType.RESET_MAP_UPDATED);
export const deleteMap = createAction(MapActionType.DELETE_MAP, props<{ id: number }>());
export const deleteMapSuccess = createAction(MapActionType.DELETE_MAP_SUCCESS);
export const resetMapDeleted = createAction(MapActionType.RESET_MAP_DELETED);

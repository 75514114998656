import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MapState } from './map.state';

export const mapFeatureKey = 'map';
export const selectMapFeature = createFeatureSelector<MapState>(mapFeatureKey);
export const selectAllMaps = createSelector(
  selectMapFeature,
  (mapState: MapState) => mapState.maps
);
export const selectLoadingMaps = createSelector(
  selectMapFeature,
  (mapState: MapState) => mapState.loadingMaps
);
export const selectMap = createSelector(selectMapFeature, (mapState: MapState) => mapState.map);
export const selectLoadingMap = createSelector(
  selectMapFeature,
  (mapState: MapState) => mapState.loadingMap
);
export const selectMapUpdated = createSelector(
  selectMapFeature,
  (mapState: MapState) => mapState.mapUpdated
);
export const selectMapDeleted = createSelector(
  selectMapFeature,
  (mapState: MapState) => mapState.mapDeleted
);
